import { EcoMailTag } from '../assets/ts/enums/eco-mail'

export function useEcoMail() {

    /**
     * Subscribe to EcoMail list with the given email and type
     * @param type the tag to use for the subscription (will append to existing tags)
     * @param email the email to subscribe
     * @param additionalData additional data to include in the subscription
     */
    async function subscribe(type: EcoMailTag, email: string, additionalData?: Partial<{ first_name: string, last_name: string }>) {
        try {
            await $fetch('/api/eco-mail', {
                method: 'POST',
                body: {
                    type: type,
                    email: email,
                    first_name: additionalData?.first_name,
                    last_name: additionalData?.last_name,
                },
            })
        } catch (e) {
            console.error(e)
        }
    }

    /**
     * Removes the tag from the subscribed email. Will not remove the email from the list.
     * @param type the tag to remove
     * @param email the email to remove the tag from
     */
    async function unsubscribe(type: EcoMailTag, email: string) {
        try {
            await $fetch('/api/eco-mail', {
                method: 'DELETE',
                body: {
                    type: type,
                    email: email,
                },
            })
        } catch (e) {
            console.error(e)
        }
    }

    return {
        subscribe,
        unsubscribe,
    }
}
